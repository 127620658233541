<template>
  <div class="registry">
    <div class="page-title d-flex pt-2 pb-3 justify-content-between">
      <h1 class="h4 m-0">
        Реестр платежей № {{ currentRegistry.number }} от
        {{ currentRegistry.date | parseDate }}
      </h1>

      <div class="d-flex align-items-center">
        <BButton class="ml-3" variant="warning"
        @click="onExport( 'registries', id )"
        >Экспорт </BButton>

        <BButton
          v-if="currentRegistry.provider_status === 'Черновик' && isContractor"
          class="ml-3"
          :to="`${$route.fullPath}/edit`"
          variant="outline-primary"
          >Редактировать</BButton
        >

        <template
          v-if="
            currentRegistry.provider_status === 'На рассмотрении' && isProvider
          "
        >
          <BButton class="ml-3" variant="danger" v-b-modal.confirm-reject>
            Отказать
          </BButton>

          <BButton class="ml-3" variant="primary" @click="approveRegistry">
            Согласовать
          </BButton>
        </template>
      </div>
    </div>

    <b-tabs v-model="tabIndex" card>
      <b-tab title="Информация">
        <b-row>
          <b-col cols="6">
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                style="width: 250px"
              >
                Номер реестра
              </div>
              <div class="px-2 py-1 flex-grow-1">
                {{ currentRegistry.number }}
              </div>
            </div>
          </b-col>

          <b-col cols="6">
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                style="width: 250px"
              >
                Дата
              </div>
              <div class="px-2 py-1 flex-grow-1">
                {{ currentRegistry.date | parseDate }}
              </div>
            </div>
          </b-col>
        </b-row>

        <div class="d-flex table-bordered mb-1">
          <div
            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
            style="width: 250px"
          >
            Статус согласования с поставщиком
          </div>
          <div class="px-2 py-1 flex-grow-1">
            {{ currentRegistry.provider_status }}
          </div>
        </div>

        <div class="d-flex table-bordered mb-1">
          <div
            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
            style="width: 250px"
          >
            Подрядчик
          </div>
          <div class="px-2 py-1 flex-grow-1">
            {{
              currentRegistry.contractor ? currentRegistry.contractor.name : ``
            }}
          </div>
        </div>

        <div class="d-flex table-bordered mb-1">
          <div
            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
            style="width: 250px"
          >
            Поставщик
          </div>
          <div class="px-2 py-1 flex-grow-1">
            {{ currentRegistry.provider ? currentRegistry.provider.name : `` }}
          </div>
        </div>

        <b-row>
          <b-col cols="6">
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                style="width: 250px"
              >
                № договора поставки
              </div>
              <div class="px-2 py-1 flex-grow-1">
                {{
                  getProviderContract(currentRegistry.provider_contract_id)
                    .number
                }}
              </div>
            </div>
          </b-col>

          <b-col cols="6">
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                style="width: 250px"
              >
                Дата договора поставки
              </div>
              <div class="px-2 py-1 flex-grow-1">
                {{
                  getProviderContract(currentRegistry.provider_contract_id).date
                    | parseDate
                }}
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6">
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                style="width: 250px"
              >
                Ответственный исполнитель
              </div>
              <div class="px-2 py-1 flex-grow-1">
                {{ currentRegistry.responsible_full_name }}
              </div>
            </div>
          </b-col>

          <b-col cols="6">
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                style="width: 250px"
              >
                Телефон
              </div>
              <div class="px-2 py-1 flex-grow-1">
                {{
                  currentRegistry.responsible_phone | VMask("# (###) ###-##-##")
                }}
              </div>
            </div>
          </b-col>
        </b-row>

        <div class="d-flex table-bordered mb-1">
          <div
            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
            style="width: 250px"
          >
            Комментарий
          </div>
          <div class="px-2 py-1 flex-grow-1">{{ currentRegistry.comment }}</div>
        </div>
      </b-tab>

      <b-tab title="Позиции">
        <TableFilter
          :columns="positionsFields"
          uniqueKey="table-2"
          @update-selected-columns="columns = $event"
          @search="filter = $event"
          @open-filter-modal="$root.$emit('bv::toggle::collapse', 'filter')"
          @clear="clearFilterData"
        />

        <DefaultTable
          :data="currentRegistry.positions"
          :fields="positionsFieldsFiltered"
          :filter="filter"
          :withIterator="true"
          emptyText="Нет позиций"
          :loading="positionsLoading"
          :noLocalSorting="true"
          :withSpecifiedTotal="`Всего сумма по документу: <b>${currentRegistry.positions_sum_amount_payment} руб.</b>`"
          @sort-change="sorting"
          @pagination-change="paginationChange"
          @per-page-change="perPageChange"
        />
      </b-tab>
    </b-tabs>

    <b-modal id="confirm-reject" title="Отказать" centered>
      <p class="m-0">Вы действительно хотите отказать в данном реестр?</p>
      <template #modal-footer="{ hide }">
        <b-button @click="hide()">Нет</b-button>
        <b-button variant="danger" @click="rejectRegistry">Да</b-button>
      </template>
    </b-modal>

    <FilterSidebar
      id="filter"
      v-model="filterData"
      @apply="updatePositionsWithFilters(true)"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import TableFilter from "@/components/TableFilter";
import DefaultTable from "@/components/Tables/Default";
import FilterSidebar from "@/components/FilterSidebar";

function initialFilterData() {
  return {
    order_number: "",
    order_date: ["", ""],
    payment_order_date: ["", ""],
    object: "",
    organization: "",
    work_agreement_number: "",
  };
}

export default {
  name: "RegistriesItem",
  components: {
    TableFilter,
    DefaultTable,
    FilterSidebar,
  },
  computed: {
    ...mapState({
      currentRegistry: (state) => state.registries.currentRegistry,
    }),
    ...mapGetters([
      "isProvider",
      "isContractor",
      "getContrAgent",
      "getProviderContract",
    ]),
    id() {
      return this.$route.params.id;
    },
    positionsFieldsFiltered() {
      let res = [];
      this.positionsFields.forEach((item) => {
        if (this.columns.filter((col) => col === item.label).length) {
          res.push(item);
        }
      });
      return res.length > 0 ? res : this.positionsFields;
    },
    breadcrumbs() {
      return [
        { title: "Главная", link: "/" },
        { title: "Заказ ПО", link: "/" },
        {
          title: "Перечень реестров платежей по договорам подряда",
          link: "/registries",
        },
        {
          title: `Реестр платежей № ${
            this.currentRegistry.number
          } от ${this.$options.filters.parseDate(this.currentRegistry.date)}`,
        },
      ];
    },
  },
  data: () => ({
    tabIndex: 0,
    positionsFields: [
      {
        key: "order_number",
        sortable: true,
        label: "Номер заказа",
        full: "Номер заказа",
        openDefault: true,
      },
      {
        key: "order_date",
        sortable: true,
        label: "Дата заказа",
        full: "Дата заказа",
        openDefault: true,
      },
      {
        key: "payment_order_number",
        sortable: true,
        label: "Номер ПП",
        full: "Номер ПП",
        openDefault: true,
      },
      {
        key: "payment_order_date",
        sortable: true,
        label: "Дата ПП",
        full: "Дата ПП",
        openDefault: true,
      },
      {
        key: "amount_payment",
        sortable: true,
        label: "Сумма платежа",
        full: "Сумма платежа",
        openDefault: true,
      },
      {
        key: "payment_type",
        sortable: true,
        label: "Вид платежа",
        full: "Вид платежа",
        openDefault: true,
      },
    ],
    columns: [],
    filter: "",
    filterData: initialFilterData(),
    pageLoad: true,
    positionsLoading: false,
    sortingData: {
      sort_field: "",
      sort_order: "",
      page: "",
      per_page: 25,
    },
  }),
  methods: {
    async loadPage() {
      await Promise.all([
        this.$store.dispatch("getCurrentRegistry", this.id),
        this.$store.dispatch("getRegistryPositions", { id: this.id }),
      ]);
      this.positionsFields.forEach((item) => {
        if (item.openDefault) {
          this.columns.push(item.label);
        }
      });
    },
    async rejectRegistry() {
      await this.$store
        .dispatch("rejectRegistry", this.id)
        .then(() => {
          this.sendSuccess("Реестр платежей успешно отклонен");
        })
        .catch((err) => {
          this.sendError(err);
        });
      this.$bvModal.hide("confirm-reject");
    },
    approveRegistry() {
      this.$store
        .dispatch("approveRegistry", this.id)
        .then(() => {
          this.sendSuccess("Реестр платежей успешно согласован");
        })
        .catch((err) => {
          this.sendError(err);
        });
    },
    sendSuccess(successText) {
      this.$bvToast.toast(successText, {
        toaster: "b-toaster-top-right",
        solid: true,
        appendToast: false,
        variant: "success",
      });
      this.$router.push("/registries");
    },
    sendError(err) {
      const errText =
        err.response.status === 422
          ? "Проверьте правильность заполненных полей"
          : "Ошибка сервера. Попробуйте позднее.";
      this.$bvToast.toast(errText, {
        toaster: "b-toaster-top-right",
        solid: true,
        appendToast: false,
        variant: "danger",
      });
    },
    async updatePositionsWithFilters(clearSort) {
      this.positionsLoading = true;
      if (clearSort) {
        this.sortingData.sort_field = "";
        this.sortingData.sort_order = "";
        this.sortingData.page = "";
      }
      await this.$store.dispatch("getRegistryPositions", {
        id: this.id,
        filters: {
          ...this.sortingData,
          ...this.filterData,
        },
      });
      this.positionsLoading = false;
    },
    clearFilterData() {
      this.filterData = initialFilterData();
      this.updatePositionsWithFilters(true);
    },
    sorting(event) {
      this.sortingData.sort_field = event.sortBy;
      this.sortingData.sort_order = event.sortDesc ? "desc" : "asc";
      this.updatePositionsWithFilters();
    },
    paginationChange(page) {
      this.sortingData.page = page;
      this.updatePositionsWithFilters();
    },
    perPageChange(perPage) {
      this.sortingData.page = "";
      this.sortingData.per_page = perPage;
      this.updatePositionsWithFilters();
    },
  },
  async mounted() {
    await this.loadPage();
  },
};
</script>

<style
    lang="sass"
    scoped
>
</style>
